/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { isEmpty, isNull, partition } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SYSTEM_COLLAPSE, USER_EXPAND } from 'common/actions/row-groups/types';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import {
  VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_ADD,
  VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_DISABLED_TOOLTIP,
  VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_REMOVE,
} from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { Widgets } from 'components';
import GridButtonWithTooltip from 'components/ActionButtons/GridButtonWithTooltip';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import CompGroupContext from 'context/CompGroupContext';
import AddGpcDialog from 'pages/Valuations/approaches/guidelinePublicCompanies/AddGpcDialogue';
import useCellOptions from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/useCellOptions';
import { parseGpcComparison } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utillities';
import { PUBLIC_COMPANIES, SORT_ASC, SORT_DESC, UPDATED_BENCHMARK_ROWS } from 'pages/Valuations/util/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useNotes } from 'services/hooks/notes';
import { dateMinusOrPlusXPeriods, getNumberValue, getStringValue, ISO_DATE_FORMAT } from 'utillities';
import { isDateInPeriods, isLastQuarter } from 'utillities/dateUtils';
import { SELECTION, VALUATION_GPC_TABLE_SLUG } from './constants';
import getGPCRowConfig from './gpc/config/getRowConfig';
import gpcRowTransformer from './gpc/config/rowTransformer';
import { AS_OF_DATE_ALIAS } from './MultiplePremiumDiscount/common/constants';
import {
  createColumns as multiplePremiumDiscountCreateColumns,
  rowConfig as multiplePremiumDiscountRowConfig,
} from './MultiplePremiumDiscount/config';
import {
  createColumns as performanceMetricsAsOfDateCreateColumns,
  rowConfig as performanceMetricsAsOfDateRowConfig,
} from './MultiplePremiumDiscount/PerformanceMetricsAsOfDate/config';
import {
  createColumns as performanceMetricsCreateColumns,
  rowConfig as performanceMetricsRowConfig,
  useCustomClasses as usePerformanceMetricsCustomClasses,
} from './PerformanceMetrics/config';
import { getCompanyPerfMetricsAsOfDate } from './utils/getCompanyPerfMetricsCalculations';
import getFlattenedData from './utils/getFlattenedData';
import { useCombinedGPCComparisonPerfMetricAttrs } from './utils/useCombinedGPCComparisonPerfMetricAttrs';
import useUpdateCustomDatePerformanceMetrics from './utils/useUpdateCustomDatePerformanceMetrics';
import { getAllCustomDatePerformanceMetrics, getFiscalYearEnd, isDateBeforeReference } from './utils/utilities';

const GuidelinePublicCompanies = ({ spreadsheets, onChange, workbook }) => {
  const gpcSpreadsheet = spreadsheets.gpc;
  const { tableData: initialTableData } = gpcSpreadsheet;
  const performanceMetricsSpreadsheet = spreadsheets.performanceMetrics;
  const performanceMetricsAsOfDateSpreadsheet = spreadsheets.performanceMetricsAsOfDate;
  const multiplePremiumDiscountSpreadsheet = spreadsheets.multiplePremiumDiscount;
  const [compsWithCustomDatePerformanceMetrics, setCompsWithCustomDatePerformanceMetrics] = useState([]);
  const { financials, approach, companyName, isDisabled } = initialTableData;
  const { use_multiple_premium_discount: useMultiplePremiumDiscountFromDB } = approach.valuations_approach_gpc;
  const [format, formatDispatch] = useFormat();
  const {
    resetConfiguration,
    compGroups,
    companyMeasurementDate,
    setAreThereChanges,
    financialsPeriods,
    financialStatementPeriodsPerMD,
    setNotesInApproach,
    measurementDate,
    gpcAttributes,
  } = useContext(ValuationContext);
  const [multiplePremiumDiscountAsOfDate, setMultiplePremiumDiscountAsOfDate] = useState(
    useMultiplePremiumDiscountFromDB
      ? approach.valuations_approach_gpc.multiple_premium_discount?.[0]?.as_of_date
      : measurementDate.date
  );

  const combinedGPCComparisonPerfMetricAttrs = useCombinedGPCComparisonPerfMetricAttrs(gpcAttributes);

  const {
    getCompsWithCustomDatePerformanceMetrics,
    validateCustomDatePerformanceMetrics,
    getCustomPerformanceMetricsForGPCComparison,
  } = useUpdateCustomDatePerformanceMetrics({
    gpcComparison: gpcSpreadsheet.tableData.approach.valuations_approach_gpc.gpc_comparison,
    format,
    gpcAttributes,
  });

  const disableMpdButton = useMemo(() => {
    const gpcComparison = approach?.valuations_approach_gpc?.gpc_comparison || [];
    return !(gpcComparison.length > 0 && 'id' in gpcComparison[0]);
  }, [approach]);

  const isInPeriods = isDateInPeriods(
    multiplePremiumDiscountAsOfDate,
    financialStatementPeriodsPerMD,
    'statement_date',
    ISO_DATE_FORMAT
  );

  const [isReadOnlyCompanyPerfMetricsAsOfDateRow, setIsReadOnlyCompanyPerfMetricsAsOfDateRow] = useState(isInPeriods);

  const [openGpcDialog, setOpenGpcDialog] = useState(false);
  const [collapsibleColumns, setCollapsibleColumns] = useState({
    ltm_revenue: true,
  });
  const [hasMultiplePremiumDiscount, setHasMultiplePremiumDiscount] = useState(
    useMultiplePremiumDiscountFromDB || false
  );
  const [multiplePremiumDiscountObjects, setMultiplePremiumDiscountObjects] = useState([]);
  const approachPanelId = approach.panelId;
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  const { customClasses: performanceMetricsCustomClasses } = usePerformanceMetricsCustomClasses();

  const [cellOptions, handleOnChange] = useCellOptions(onChange, approach.valuations_approach_gpc);

  const [hasPerfMetricsAsOfDate, setHasPerfMetricsAsOfDate] = useState(hasMultiplePremiumDiscount || false);

  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, {
    [SELECTION]: hasMultiplePremiumDiscount ? USER_EXPAND : SYSTEM_COLLAPSE,
  });

  // Store companies as they are removed from the table
  const removedGPCs = useRef([]);

  const getUpdatedGPC = ({ gpc, customPerformanceMetrics }) => {
    if (
      approach.valuations_approach_gpc.gpc_comparison.findIndex(
        comparison => comparison.cap_iq_id === gpc.cap_iq_id.value
      ) >= 0
    ) {
      return null;
    }

    const flattened = getFlattenedData({
      data: gpc,
      attributes: combinedGPCComparisonPerfMetricAttrs,
    });

    flattened.ltm_revenue_enabled = true;
    flattened.ltm_ebitda_enabled = true;
    flattened.ntm_revenue_enabled = true;
    flattened.ntm_ebitda_enabled = true;
    flattened.name = flattened.company_name;
    const { calendar_years_financials: calendarYearsFinancials } = parseGpcComparison({
      data: gpc,
      attributes: combinedGPCComparisonPerfMetricAttrs,
    });
    flattened.calendar_years_financials = calendarYearsFinancials;
    flattened.reference_for_backsolve = flattened.cap_iq_id;
    flattened.custom_date_performance_metrics = customPerformanceMetrics;

    return {
      ...approach,
      valuations_approach_gpc: {
        ...approach.valuations_approach_gpc,
        gpc_comparison: [...approach.valuations_approach_gpc.gpc_comparison, flattened],
      },
    };
  };

  const [lastSortedColumn, setLastSortedColumn] = useState(null);

  const updateApproach = useCallback(
    (approachGpc, tableData, updatedSortedColumn = {}) => {
      const rowConfigParams = {
        companyName,
        approach: approachGpc,
        allCompGroups: compGroups,
        isDisabled,
      };

      // Update GPC Spreadsheet

      gpcSpreadsheet.reset({
        tableData,
        rowConfig: getGPCRowConfig(rowConfigParams),
        columns: gpcRowTransformer(approachGpc, financials, financialsPeriods, updatedSortedColumn),
      });

      // Update Performance Metrics Spreadsheet

      const performanceMetricsColumns = performanceMetricsCreateColumns({
        valuationsApproachGpc: approachGpc,
        financials,
      });

      performanceMetricsSpreadsheet.reset({
        columns: performanceMetricsColumns,
        rowConfig: performanceMetricsRowConfig({
          companyName: rowConfigParams.companyName,
          customClasses: performanceMetricsCustomClasses,
          valuationsApproachGpc: rowConfigParams.approach,
        }),
        tableData: { columns: performanceMetricsColumns, customData: cellOptions },
      });

      // Update Performance Metrics As Of Date Spreadsheet
      const customDateColumn = approachGpc?.multiple_premium_discount && approachGpc?.multiple_premium_discount[0];
      const asOfDate = customDateColumn?.as_of_date || measurementDate.date;
      const customDatePerfMetrics = getAllCustomDatePerformanceMetrics(approachGpc.gpc_comparison, asOfDate);
      const { rowMustBeReadOnly } = tableData.approach.valuations_approach_gpc;
      const fiscalYearEnd = getFiscalYearEnd(asOfDate, companyMeasurementDate);
      const performanceMetricsAsOfDateColumns = performanceMetricsAsOfDateCreateColumns({
        comparisons: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : approachGpc.gpc_comparison,
        financials,
        companyName,
        financialsPeriods: financialStatementPeriodsPerMD,
        fiscalYearEnd,
        asOfDate,
        isReadOnlyCompanyPerfMetricsAsOfDateRow: rowMustBeReadOnly || isReadOnlyCompanyPerfMetricsAsOfDateRow,
        companyAsOfDatePerformanceMetrics: approachGpc?.company_as_of_date_performance_metrics,
        measurementDate: measurementDate.date,
        performanceMetricsSheetColumns: performanceMetricsSpreadsheet.columns,
      });

      const performanceMetricsAsOfDateCommonRowConfig = {
        asOfDate,
        companyName,
        customClasses: performanceMetricsCustomClasses,
        comparisons: !isEmpty(customDatePerfMetrics) ? customDatePerfMetrics : approachGpc.gpc_comparison,
      };

      // Set the Performance Metrics As Of Date Spreadsheet to read-only if there is no multiple premium discount
      performanceMetricsAsOfDateSpreadsheet.reset({
        rowConfig: performanceMetricsAsOfDateRowConfig({
          ...performanceMetricsAsOfDateCommonRowConfig,
          isReadOnlyCompanyPerfMetricsAsOfDateRow: rowMustBeReadOnly || isReadOnlyCompanyPerfMetricsAsOfDateRow,
        }),
      });

      if (!isEmpty(approachGpc?.multiple_premium_discount)) {
        performanceMetricsAsOfDateSpreadsheet.reset({
          rowConfig: performanceMetricsAsOfDateRowConfig({
            ...performanceMetricsAsOfDateCommonRowConfig,
            isReadOnlyCompanyPerfMetricsAsOfDateRow: rowMustBeReadOnly || isReadOnlyCompanyPerfMetricsAsOfDateRow,
          }),
          columns: performanceMetricsAsOfDateColumns,
          tableData: {
            ...tableData,
            asOfDate,
            companyAsOfDatePerformanceMetrics: approachGpc?.company_as_of_date_performance_metrics,
          },
        });

        // Update Multiple Premium Discount Spreadsheet
        const multiplePremiumDiscountColumns = multiplePremiumDiscountCreateColumns({
          measurementDate,
          multiplePremiumDiscountFromDB: approachGpc.multiple_premium_discount,
          valuationDate: tableData.approach.valuation_date ?? measurementDate?.date,
        });
        multiplePremiumDiscountSpreadsheet.reset({
          columns: multiplePremiumDiscountColumns,
          rowConfig: multiplePremiumDiscountRowConfig({
            companyName,
            maxDate: dateMinusOrPlusXPeriods({
              date: tableData.approach.valuation_date ?? measurementDate?.date,
              period: 'days',
              amount: -1,
              format: ISO_DATE_FORMAT,
            }),
          }),
        });
      }

      if (approachGpc.is_benchmark) {
        document.dispatchEvent(new Event(UPDATED_BENCHMARK_ROWS));
      }

      resetConfiguration();
    },
    [
      isReadOnlyCompanyPerfMetricsAsOfDateRow,
      cellOptions,
      companyName,
      compGroups,
      companyMeasurementDate,
      isDisabled,
      gpcSpreadsheet,
      financials,
      financialsPeriods,
      financialStatementPeriodsPerMD,
      performanceMetricsSpreadsheet,
      performanceMetricsAsOfDateSpreadsheet,
      multiplePremiumDiscountSpreadsheet,
      measurementDate,
      performanceMetricsCustomClasses,
      resetConfiguration,
    ]
  );

  const onAdd = async gpcData => {
    // To make the implementation of onAdd here and in the comp groups consistent, the argument
    // passed to the function is an array (even when it contains a single object in this case)
    const { gpc } = gpcData[0];
    let customPerformanceMetrics = [];
    if (hasMultiplePremiumDiscount) {
      customPerformanceMetrics = await getCustomPerformanceMetricsForGPCComparison(multiplePremiumDiscountAsOfDate, [
        gpc.cap_iq_id.value,
      ]);
    }
    const updatedApproach = getUpdatedGPC({ gpc, customPerformanceMetrics });
    if (updatedApproach) {
      const { valuations_approach_gpc: approachGpc } = updatedApproach;

      const { tableData } = gpcSpreadsheet;

      tableData.approach.valuations_approach_gpc = {
        ...approachGpc,
        gpc_comparison: approachGpc.gpc_comparison.map((comp, index) => ({
          ...comp,
          order: index,
        })),
      };
      updateApproach(approachGpc, tableData);
    }
  };

  const onRemove = async deletedComparison => {
    const { valuations_approach_gpc: gpc } = approach;
    const compId = deletedComparison.comp_group_id || deletedComparison.gpc_approach_comp_group;
    const currentDeletedCompGroups = gpc.deleted_comp_groups || [];

    // 1. update the specific approach to no longer include this thing
    // Exclude the deleted comparison and others removed previously (if any)
    const filteredComparisons = approach?.valuations_approach_gpc?.gpc_comparison
      .filter(
        comparison =>
          comparison.cap_iq_id !== deletedComparison.cap_iq_id
          && !removedGPCs.current.map(item => item.capitalIQId).includes(comparison.cap_iq_id)
      )
      .map((comp, index) => ({
        ...comp,
        order: index,
      }));

    // Remove the comp group relation of the other companies
    // with the same comp group as the deleted one
    filteredComparisons.forEach(company => {
      if (company.gpc_approach_comp_group === compId) {
        delete company.gpc_approach_comp_group;
      }
      if (company.comp_group_id === compId) {
        delete company.comp_group_id;
      }
    });

    const objectKeyCompGroup = gpc?.valuationapproachgpccompgroup_set?.find(
      item => item.id === deletedComparison.gpc_approach_comp_group
    );

    const valuationKeysToSave = gpc?.valuationapproachgpccompgroup_set?.filter(
      item => item.comp_group !== objectKeyCompGroup?.comp_group
    );

    const tmpValuationsApproachGpc = {
      ...gpc,
      gpc_comparison: filteredComparisons,
      valuationapproachgpccompgroup_set: valuationKeysToSave,
    };

    removedGPCs.current.push({
      // Needed for new approaches that have not been saved yet
      capitalIQId: deletedComparison.cap_iq_id,
      // Existing approaches (already persisted to the database) have an id
      gpcComparisonsId: deletedComparison.id ? deletedComparison.id : null,
    });

    const { tableData } = gpcSpreadsheet;

    tableData.approach.valuations_approach_gpc = {
      ...gpc,
      valuationapproachgpccompgroup_set: valuationKeysToSave,
      gpc_comparison: filteredComparisons,
      // Add deleted_gpc_comparisons only in existing approaches
      // since they do not make sense in brand new ones
      deleted_gpc_comparisons: approach.id
        ? removedGPCs.current.map(item => item.gpcComparisonsId).filter(id => !isNull(id))
        : [],
      // If the company has a comp group, remove it from the list of deleted comp groups
      deleted_comp_groups: deletedComparison.gpc_approach_comp_group
        ? [...currentDeletedCompGroups, ...[compId]]
        : currentDeletedCompGroups,
    };

    updateApproach(tmpValuationsApproachGpc, tableData);
    setCompsWithCustomDatePerformanceMetrics(filteredComparisons);
    setAreThereChanges(true);
  };

  const getFlattenedItem = useCallback(
    companyItem => {
      const { gpc } = companyItem;
      const { comp_group_id, comp_group_name, ...newGpc } = gpc;
      const flattened = getFlattenedData({ data: newGpc, attributes: combinedGPCComparisonPerfMetricAttrs });

      flattened.ltm_revenue_enabled = true;
      flattened.ltm_ebitda_enabled = true;
      flattened.ntm_revenue_enabled = true;
      flattened.ntm_ebitda_enabled = true;
      flattened.reference_for_backsolve = flattened.cap_iq_id;
      flattened.name = flattened.company_name;
      const { calendar_years_financials: calendarYearsFinancials } = parseGpcComparison({
        data: gpc,
        attributes: combinedGPCComparisonPerfMetricAttrs,
      });
      flattened.calendar_years_financials = calendarYearsFinancials;

      if (comp_group_id && comp_group_name) {
        flattened.comp_group_id = comp_group_id;
        flattened.comp_group_name = comp_group_name;
        flattened.temp_ref = comp_group_id;
      }
      return flattened;
    },
    [combinedGPCComparisonPerfMetricAttrs]
  );

  const getCompGroupsData = useCallback(
    (comparisons, compGroupToDelete) => {
      const { tableData } = gpcSpreadsheet;
      const { valuationapproachgpccompgroup_set } = tableData.approach.valuations_approach_gpc;
      const compGroupsData
        = valuationapproachgpccompgroup_set?.filter(item => compGroupToDelete !== item.comp_group) || [];
      const newCompGroups = comparisons
        .filter(comparison => comparison.comp_group_id && compGroupToDelete !== comparison.comp_group_id)
        .filter(item => !compGroupsData.some(compGroupData => compGroupData.id === item.comp_group_id))
        .map(comparisonCompGroupItem => ({
          isNew: true,
          comp_group: comparisonCompGroupItem.comp_group_id,
          comp_group_name: comparisonCompGroupItem.comp_group_name,
          use_latest_comp_group_version: true,
        }));
      const uniqueNewCompGroupsData = [...new Map(newCompGroups.map(item => [item.comp_group, item])).values()];
      return [...compGroupsData, ...uniqueNewCompGroupsData];
    },
    [gpcSpreadsheet]
  );

  const getComparisonsData = useCallback(
    (comparisons, comparisonsToDelete, compGroupsData, useLatestVersion) => {
      const { tableData } = gpcSpreadsheet;
      const { gpc_comparison } = tableData.approach.valuations_approach_gpc;
      const remainingTransactions = gpc_comparison.filter(
        currentTransaction => !comparisonsToDelete.includes(currentTransaction.id || currentTransaction.temp_id)
      );
      const comparisonsWithUpdatedCompGroup = remainingTransactions.map(comparison => {
        const transactionCompGroupId = comparison.gpc_approach_comp_group || comparison.compgroupId;
        const hasCompGroup = compGroupsData.some(compGroup => compGroup.id === transactionCompGroupId);
        if (!hasCompGroup) {
          const { gpc_approach_comp_group, comp_group_id, comp_group_name, temp_ref, ...newTransactionObj }
            = comparison;
          return newTransactionObj;
        }

        return comparison;
      });
      comparisons.forEach(comparison => {
        const currentTransactionIdx = comparisonsWithUpdatedCompGroup.findIndex(
          current => current.cap_iq_id === comparison.cap_iq_id
        );
        if (currentTransactionIdx !== -1) {
          const currentTransactionData = comparisonsWithUpdatedCompGroup[currentTransactionIdx];
          if (useLatestVersion) {
            comparisonsWithUpdatedCompGroup[currentTransactionIdx] = {
              id: currentTransactionData.id,
              ...comparison,
            };
          } else {
            const { comp_group_id, comp_group_name, temp_ref, gpc_approach_comp_group, ...updatedCurrentTransaction }
              = currentTransactionData;
            comparisonsWithUpdatedCompGroup[currentTransactionIdx] = {
              id: currentTransactionData.id,
              ...updatedCurrentTransaction,
            };
          }
        } else {
          comparisonsWithUpdatedCompGroup.push(comparison);
        }
      });
      return comparisonsWithUpdatedCompGroup;
    },
    [gpcSpreadsheet]
  );

  const getDeletedCompGroupsIDs = useCallback(
    compGroupToDelete => {
      const { tableData } = gpcSpreadsheet;
      const { valuationapproachgpccompgroup_set } = tableData.approach.valuations_approach_gpc;
      const findMatch = compGroupItem => compGroupToDelete === compGroupItem.comp_group;
      return valuationapproachgpccompgroup_set
        .filter(compGroupItem => !compGroupItem.isNew && findMatch(compGroupItem))
        .map(compGroupItem => compGroupItem.id);
    },
    [gpcSpreadsheet]
  );

  const saveCompGroups = useCallback(
    async (comparisons, useLatestVersion, comparisonsToDelete, compGroupToDelete) => {
      const { tableData } = gpcSpreadsheet;
      const validComparisons = comparisons.filter(item => item.valid_gpc_results);

      const flattenedComparisons = validComparisons.map(companyItem => getFlattenedItem(companyItem));
      const compGroupsData = getCompGroupsData(flattenedComparisons, compGroupToDelete);
      if (hasMultiplePremiumDiscount) {
        const capIqIds = flattenedComparisons.map(item => item.cap_iq_id);
        const asOfCompData = await getCustomPerformanceMetricsForGPCComparison(
          multiplePremiumDiscountAsOfDate,
          capIqIds
        );
        flattenedComparisons.forEach(comp => {
          const matchingCompData = asOfCompData.find(compData => compData.cap_iq_id === comp.cap_iq_id);
          if (matchingCompData) {
            comp.custom_date_performance_metrics = [matchingCompData];
          }
        });
      }
      const gpcComparison = getComparisonsData(
        flattenedComparisons,
        comparisonsToDelete,
        compGroupsData,
        useLatestVersion
      );

      const valuationApproachGPC = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: gpcComparison,
        valuationapproachgpccompgroup_set: compGroupsData,
      };

      if (!isEmpty(comparisonsToDelete)) {
        valuationApproachGPC.deleted_gpc_comparisons = comparisonsToDelete;
      }
      if (compGroupToDelete) {
        const deletedCompGroups = getDeletedCompGroupsIDs(compGroupToDelete);
        valuationApproachGPC.deleted_comp_groups = deletedCompGroups;
      }
      tableData.approach.valuations_approach_gpc = valuationApproachGPC;
      updateApproach(valuationApproachGPC, tableData);
    },
    [
      getCompGroupsData,
      getComparisonsData,
      getCustomPerformanceMetricsForGPCComparison,
      getDeletedCompGroupsIDs,
      gpcSpreadsheet,
      hasMultiplePremiumDiscount,
      multiplePremiumDiscountAsOfDate,
      updateApproach,
      getFlattenedItem,
    ]
  );

  const deleteCompGroup = useCallback(
    () => compGroupToDelete => {
      const { tableData } = gpcSpreadsheet;

      const { gpc_comparison, valuationapproachgpccompgroup_set, deleted_gpc_comparisons, deleted_comp_groups }
        = tableData.approach.valuations_approach_gpc;
      const currentDeletedComparisons = deleted_gpc_comparisons || [];
      const currentDeletedCompGroups = deleted_comp_groups || [];

      const comparisonResult = partition(gpc_comparison, item => {
        const compGroupId = item.gpc_approach_comp_group || item.comp_group_id;
        return compGroupId === compGroupToDelete;
      });

      const compGroupData = valuationapproachgpccompgroup_set.filter(compGroup => {
        const compGroupId = compGroup.id || compGroup.comp_group;
        return compGroupId !== compGroupToDelete;
      });

      const comparisonsToDelete = comparisonResult[0];
      const remainingComparisons = comparisonResult[1];

      const updatedApproach = {
        gpc_comparison: remainingComparisons,
        valuationapproachgpccompgroup_set: compGroupData,
        deleted_comp_groups: [...new Set([...currentDeletedCompGroups, ...[compGroupToDelete]])],
      };

      if (comparisonsToDelete) {
        updatedApproach.deleted_gpc_comparisons = [...currentDeletedComparisons, ...comparisonsToDelete]
          ?.filter(({ id }) => id)
          ?.map(({ id }) => id);
      }
      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        ...updatedApproach,
      };
      updateApproach(tableData.approach.valuations_approach_gpc, tableData);
    },
    [gpcSpreadsheet, updateApproach]
  );

  const compGroupContextValue = useMemo(
    () => ({
      saveCompGroups,
      deleteCompGroup,
      tableData: gpcSpreadsheet.tableData,
      approachType: PUBLIC_COMPANIES,
      cellOptions,
      setUseMultiplePremiumDiscount: setHasMultiplePremiumDiscount,
      useMultiplePremiumDiscount: hasMultiplePremiumDiscount,
    }),
    [cellOptions, deleteCompGroup, gpcSpreadsheet, saveCompGroups, hasMultiplePremiumDiscount]
  );

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged }];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  // Multiple Premium Discount
  useEffect(() => {
    setRowGroups({ type: hasMultiplePremiumDiscount ? USER_EXPAND : SYSTEM_COLLAPSE, row: SELECTION });
  }, [hasMultiplePremiumDiscount, setRowGroups]);

  const handleMultiplePremiumDiscount = useCallback(() => {
    setHasMultiplePremiumDiscount(prevState => !prevState);
  }, []);

  useEffect(() => {
    multiplePremiumDiscountSpreadsheet.setShouldValidate(hasMultiplePremiumDiscount);
    performanceMetricsAsOfDateSpreadsheet.setShouldValidate(hasMultiplePremiumDiscount);
  }, [hasMultiplePremiumDiscount, multiplePremiumDiscountSpreadsheet, performanceMetricsAsOfDateSpreadsheet]);

  useEffect(() => {
    const { tableData } = gpcSpreadsheet;
    if (!hasMultiplePremiumDiscount && tableData.approach.valuations_approach_gpc.multiple_premium_discount?.length) {
      setMultiplePremiumDiscountObjects(tableData.approach.valuations_approach_gpc.multiple_premium_discount);
      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        company_as_of_date_performance_metrics: [],
        multiple_premium_discount: [],
        isDateInPeriods: true,
      };
      updateApproach(tableData.approach.valuations_approach_gpc, tableData);
    } else if (hasMultiplePremiumDiscount && multiplePremiumDiscountObjects.length) {
      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        multiple_premium_discount: multiplePremiumDiscountObjects,
      };
      setMultiplePremiumDiscountObjects([]);
      updateApproach(tableData.approach.valuations_approach_gpc, tableData);
    }
  }, [gpcSpreadsheet, updateApproach, hasMultiplePremiumDiscount, multiplePremiumDiscountObjects]);

  const updateCompsWithMetrics = useCallback((compsWithMetrics, correspondingCompsWithMetrics) => {
    let updatedCompsWithMetrics = [...compsWithMetrics];

    // Loop through each item in compsWithMetrics
    updatedCompsWithMetrics = updatedCompsWithMetrics.map(compWithMetric => {
      // Find the correlating comp in the correspondingCompsWithMetrics
      const correspondingComp = correspondingCompsWithMetrics.find(comp => comp.id === compWithMetric.id);

      // If there is a corresponding comp, and it has at least one item in its custom_date_performance_metrics array
      if (correspondingComp && correspondingComp.custom_date_performance_metrics.length > 0) {
        // Copy the metric from correspondingComp to compWithMetric
        const correspondingMetric = correspondingComp.custom_date_performance_metrics[0];

        // Assign id of the compWithMetric.custom_date_performance_metrics[0]
        // (make sure compWithMetric.custom_date_performance_metrics[0] object exists)
        if (compWithMetric.custom_date_performance_metrics.length > 0) {
          compWithMetric.custom_date_performance_metrics[0].id = correspondingMetric.id;
        }
      }

      return compWithMetric;
    });

    return updatedCompsWithMetrics;
  }, []);

  const updateUseMultiplePremiumDiscount = useCallback(async () => {
    const { tableData } = gpcSpreadsheet;
    tableData.approach.valuations_approach_gpc = {
      ...tableData.approach.valuations_approach_gpc,
      use_multiple_premium_discount: hasMultiplePremiumDiscount,
    };
    updateApproach(tableData.approach.valuations_approach_gpc, tableData);
  }, [gpcSpreadsheet, updateApproach, hasMultiplePremiumDiscount]);

  const updateCustomDatePerformanceMetrics = useCallback(async () => {
    const { tableData } = gpcSpreadsheet;
    const updatedCompsWithMetrics = updateCompsWithMetrics(
      compsWithCustomDatePerformanceMetrics,
      tableData.approach.valuations_approach_gpc.gpc_comparison
    );

    const approachGpc = tableData.approach.valuations_approach_gpc;
    const customDateColumn = approachGpc?.multiple_premium_discount?.[0];
    const asOfDate = customDateColumn?.as_of_date || measurementDate.date;
    const companyAsOfDatePerformanceMetricsId = approachGpc.company_as_of_date_performance_metrics?.[0]?.id;
    const fiscalYearEnd = getFiscalYearEnd(asOfDate, companyMeasurementDate);
    if (
      isDateInPeriods(asOfDate, financialStatementPeriodsPerMD, 'statement_date', ISO_DATE_FORMAT)
      || isLastQuarter(asOfDate, fiscalYearEnd)
    ) {
      const { tmpCustomCompanyPerfMetrics, rowMustBeReadOnly } = getCompanyPerfMetricsAsOfDate({
        asOfDate,
        financialsPeriods: financialStatementPeriodsPerMD,
        companyName,
        fiscalYearEnd,
      });

      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: updatedCompsWithMetrics,
        company_as_of_date_performance_metrics: hasMultiplePremiumDiscount
          ? [{ ...tmpCustomCompanyPerfMetrics, id: companyAsOfDatePerformanceMetricsId }]
          : [],
        rowMustBeReadOnly,
      };
    } else {
      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: updatedCompsWithMetrics,
        rowMustBeReadOnly: false,
        company_as_of_date_performance_metrics: hasMultiplePremiumDiscount
          ? [{ id: companyAsOfDatePerformanceMetricsId }]
          : [],
      };
    }

    updateApproach(tableData.approach.valuations_approach_gpc, tableData);
  }, [
    gpcSpreadsheet,
    updateCompsWithMetrics,
    compsWithCustomDatePerformanceMetrics,
    measurementDate.date,
    companyMeasurementDate,
    financialStatementPeriodsPerMD,
    updateApproach,
    companyName,
    hasMultiplePremiumDiscount,
  ]);

  const handleAsOfDateAlias = useCallback(
    async value => {
      const tmpCompsWithCustomDatePerformanceMetrics = await getCompsWithCustomDatePerformanceMetrics(value);
      const compsWithValidatedCustomDatePerformanceMetrics = validateCustomDatePerformanceMetrics(
        tmpCompsWithCustomDatePerformanceMetrics
      );
      const fiscalYearEnd = getFiscalYearEnd(value, companyMeasurementDate);
      setIsReadOnlyCompanyPerfMetricsAsOfDateRow(
        isDateInPeriods(value, financialStatementPeriodsPerMD, 'statement_date', ISO_DATE_FORMAT)
          || isLastQuarter(value, fiscalYearEnd)
      );
      setMultiplePremiumDiscountAsOfDate(value);
      setCompsWithCustomDatePerformanceMetrics(compsWithValidatedCustomDatePerformanceMetrics);
      const date = moment(value).format(ISO_DATE_FORMAT);
      setHasPerfMetricsAsOfDate(date !== measurementDate.date);
    },
    [
      getCompsWithCustomDatePerformanceMetrics,
      validateCustomDatePerformanceMetrics,
      companyMeasurementDate,
      financialStatementPeriodsPerMD,
      measurementDate.date,
    ]
  );

  const onMultiplePremiumDiscountChange = useCallback(
    async (cell, value) => {
      onChange(cell, value);
      if (cell.alias === AS_OF_DATE_ALIAS) {
        if (isDateBeforeReference(value, measurementDate.date)) {
          await handleAsOfDateAlias(value);
        } else {
          setHasPerfMetricsAsOfDate(false);
        }
      }
    },
    [onChange, handleAsOfDateAlias, measurementDate.date]
  );

  useEffect(() => {
    if (!isEmpty(compsWithCustomDatePerformanceMetrics)) {
      updateCustomDatePerformanceMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compsWithCustomDatePerformanceMetrics]);

  // Update the Use Multiple Premium Discount when clicking on the button
  useEffect(() => {
    updateUseMultiplePremiumDiscount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMultiplePremiumDiscount]);

  // End of Multiple Premium Discount

  const sortGPC = useCallback(
    (key, isStringColumn = false) => {
      const { tableData } = gpcSpreadsheet;

      const currentSortedColumn = getStringValue(lastSortedColumn?.[key]);
      const currentSortedColumnIsDesc = currentSortedColumn === SORT_DESC;

      const updatedSortedColumn = {
        [key]: currentSortedColumnIsDesc ? SORT_ASC : SORT_DESC,
      };

      const sortedComparison = tableData.approach.valuations_approach_gpc.gpc_comparison.sort((a, b) => {
        if (a?.[key] && b?.[key]) {
          setLastSortedColumn(updatedSortedColumn);

          if (currentSortedColumnIsDesc) {
            return isStringColumn
              ? getStringValue(a?.[key]).localeCompare(getStringValue(b?.[key]))
              : getNumberValue(a?.[key]) - getNumberValue(b?.[key]);
          }

          return isStringColumn
            ? getStringValue(b?.[key]).localeCompare(getStringValue(a?.[key]))
            : getNumberValue(b?.[key]) - getNumberValue(a?.[key]);
        }

        return 0;
      });

      const valuationApproachGPC = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: sortedComparison.map((comp, index) => ({
          ...comp,
          order: index,
        })),
      };

      tableData.approach.valuations_approach_gpc = valuationApproachGPC;
      updateApproach(valuationApproachGPC, tableData, updatedSortedColumn);
    },
    [gpcSpreadsheet, lastSortedColumn, updateApproach]
  );

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CompGroupContext.Provider value={compGroupContextValue}>
        <ScalarSpreadsheet
          {...gpcSpreadsheet}
          accessSheet
          addSingleRow={() => setOpenGpcDialog(true)}
          rowGroups={rowGroups}
          setRowGroups={setRowGroups}
          allowSortColumn
          collapsibleColumns={collapsibleColumns}
          deleteRowFn={onRemove}
          format={format}
          formatDispatch={formatDispatch}
          key={gpcSpreadsheet.name}
          onChange={handleOnChange}
          setCollapsibleColumns={setCollapsibleColumns}
          sheet={gpcSpreadsheet}
          sortColumnFn={sortGPC}
          sortedColumn={lastSortedColumn}
          tableTerms={{
            tableSlug: VALUATION_GPC_TABLE_SLUG,
          }}
          workbook={workbook}
        />
      </CompGroupContext.Provider>
      <Grid container spacing={3}>
        <Grid item xs={12} container direction="column" alignItems="flex-end" justifyContent="flex-end">
          <AddGpcDialog
            onAdd={onAdd}
            saveCompGroups={saveCompGroups}
            deleteCompGroup={deleteCompGroup}
            tableData={initialTableData}
            source="valuation"
            currentComps={gpcSpreadsheet.tableData.approach.valuations_approach_gpc.gpc_comparison}
            openGpcDialog={openGpcDialog}
            setOpenGpcDialog={setOpenGpcDialog}
            isDisabled={isDisabled}
          />
        </Grid>
      </Grid>
      <br />
      <ScalarSpreadsheet
        {...performanceMetricsSpreadsheet}
        key={performanceMetricsSpreadsheet.name}
        onChange={onChange}
        sheet={performanceMetricsSpreadsheet}
        workbook={workbook}
        format={format}
        formatDispatch={formatDispatch}
        displayColumnIndicator
        displayRowIndicator
      />
      <br />
      <br />
      <GridButtonWithTooltip
        tooltipText={disableMpdButton ? VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_DISABLED_TOOLTIP : ''}
        buttonText={
          hasMultiplePremiumDiscount
            ? VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_REMOVE
            : VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_ADD
        }
        onClick={handleMultiplePremiumDiscount}
        disabled={isDisabled || disableMpdButton}
        className="btn-table-aligned"
      />
      <br />
      {hasMultiplePremiumDiscount && hasPerfMetricsAsOfDate && (
        <>
          <ScalarSpreadsheet
            {...performanceMetricsAsOfDateSpreadsheet}
            key={performanceMetricsAsOfDateSpreadsheet.name}
            onChange={onChange}
            sheet={performanceMetricsAsOfDateSpreadsheet}
            workbook={workbook}
            format={format}
            formatDispatch={formatDispatch}
            displayColumnIndicator
            displayRowIndicator
          />
          <br />
          <br />
        </>
      )}
      {hasMultiplePremiumDiscount && (
        <ScalarSpreadsheet
          {...multiplePremiumDiscountSpreadsheet}
          key={multiplePremiumDiscountSpreadsheet.name}
          onChange={onMultiplePremiumDiscountChange}
          sheet={multiplePremiumDiscountSpreadsheet}
          workbook={workbook}
          format={format}
          formatDispatch={formatDispatch}
          displayColumnIndicator
          displayRowIndicator
        />
      )}

      <Widgets
        notesProps={{
          pageType: VALUATIONS_PAGE_VALUE,
          pageTypeKey: VALUATIONS_PAGE_KEY,
          pageTypeId: approach.id,
          notes,
          isApproach: true,
          setNotes,
          onAddNote,
          onUpdateNotes,
          onDeleteNote,
          isDisabled,
        }}
      />
    </Box>
  );
};

GuidelinePublicCompanies.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
};

export default GuidelinePublicCompanies;
